import React from "react";
import Countdown from "./components/Countdown";

function App() {
    return (
        <div>
            <Countdown/>
        </div>
    );
}

export default App;
