import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    ChakraProvider,
    extendTheme,
    Flex,
    HStack,
    Image,
    Input,
    Text,
    useInterval,
    VStack,
} from "@chakra-ui/react";
import {confirmAlert} from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./countdown.css";
import {AnimatePresence} from "framer-motion";
import Confetti from "react-confetti";
import {useWindowSize} from "react-use";
import {animations} from "react-animation";
import timeOverSound from "../audio/happy-long-10700.mp3";
import blankaudio from "../audio/Blank.mp3";
import "bootstrap/dist/css/bootstrap.min.css";
import {Col, Container, Row} from "react-bootstrap";

function Countdown() {
    //The main function that creates the countdown timer.
    const [time, setTime] = useState({minutes: 5, seconds: 0});
    const [isRunning, setIsRunning] = useState(false);
    const [isTimeUp, setIsTimeUp] = useState(false);
    const {width, height} = useWindowSize();
    const refresh = () => window.location.reload();
    const audio = new Audio(timeOverSound);
    const blankaudiovar = new Audio(blankaudio);
    const theme = extendTheme({
        // Custom theme settings
        textStyles: {
            h1: {
                fontFamily: ["TT Norms Pro"],
                fontSize: ["120"],
                fontWeight: "500",
                lineHeight: "110%",
            },
            h2: {
                fontFamily: ["TT Norms Pro"],
                fontSize: ["20px", "40px"],
                fontWeight: "500",
                lineHeight: "110%",
            },
            p: {
                fontFamily: ["TT Norms Pro"],
                fontSize: ["15px", "20px"],
                fontWeight: "400",
                lineHeight: "110%",
            },
        },
    });


    useEffect(() => {
        if (isTimeUp) {
            blankaudiovar.src = audio.src;
            blankaudiovar.play();
        }
    }, [isTimeUp]);


    const handleStart = () => {
        // A function that sets the isRunning state to true
        setIsRunning(true);
        blankaudiovar.loop = true;
        blankaudiovar.play();
    };

    const handlePause = () => {
        // A function that sets the isRunning state to false
        setIsRunning(false);
    };

    const handleReset = () => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <Box
                        bg="white"
                        p="25"
                        borderRadius="3px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                        animation={animations.slideIn}
                        className="custom-popup"
                    >
                        <Text
                            style={{animation: animations.slideIn}}
                            as="h2"
                            fontFamily="TT Norms Pro"
                            fontSize={["20px", "30px"]}
                            fontWeight="500"
                            mt={15}
                        >
                            CONFIRMATION
                        </Text>

                        <Text
                            as="p"
                            style={{animation: animations.slideIn}}
                            fontFamily="TT Norms Pro"
                            fontSize={["15px", "20px"]}
                            fontWeight="400"
                        >
                            ARE YOU SURE YOU WANT TO RESET THE TIMER?
                        </Text>

                        <Flex justifyContent="space-between" w="full">
                            <Button
                                className="button"
                                bg="#4696c1"
                                color="white"
                                borderRadius="3px"
                                fontFamily="TT Norms Pro"
                                padding="40px"
                                marginTop={20}
                                onClick={onClose}
                                fontSize={20}
                            >
                                No
                            </Button>
                            <Button
                                bg="#4696c1"
                                color="white"
                                borderRadius="3px"
                                fontFamily="TT Norms Pro"
                                padding="40px"
                                marginTop={20}
                                marginLeft={10}
                                fontSize={20}
                                onClick={() => {
                                    handleRestart();
                                    onClose();
                                }}
                            >
                                Yes
                            </Button>
                        </Flex>
                    </Box>
                );
            },
            // disable clicking outside of the popup and pressing the escape key
            closeOnClickOutside: false,
            closeOnEscape: false,
            // add a class to the overlay to change the background color
            overlayClassName: "custom-overlay",
        });
    };

    const handleRestart = () => {
        refresh(true);
    };

    const handleChange = (name, value) => {
        if (isRunning) {
            return;
        }
        const parsedValue = parseInt(value);
        const newValue = isNaN(parsedValue) ? 0 : parsedValue;
        setTime((prevTime) => ({...prevTime, [name]: newValue}));
    };

    const handleBlur = (name) => {
        if (time[name] < 0) {
            setTime((prevTime) => ({...prevTime, [name]: 0}));
        }
        if (name === "minutes" && time[name] > 99) {
            setTime((prevTime) => ({...prevTime, [name]: 99}));
        }
        if (name === "seconds" && time[name] > 59) {
            setTime((prevTime) => ({...prevTime, [name]: 59}));
        }
    };

    const InputFields = () => {
        return (
            <Container className="fixedHeight mb-4">
                <Box>
                    <HStack spacing={8} justify="center" alignItems={"center"}>
                        <VStack minW={""}>
                            <Text className="numText">MINUTES</Text>
                            <Input
                                className="inputField"
                                style={{animation: animations.popIn}}
                                type={"number"}
                                fontWeight={"500"}
                                fontFamily="TT Norms Pro"
                                name="minutes"
                                pattern="\d*"
                                inputMode="numeric"
                                value={time.minutes.toString().padStart(2, "0")}
                                min={0}
                                max={99}
                                onChange={(event) => {
                                    if (event?.target.value < 100) {
                                        handleChange("minutes", event.target.value);
                                    } else {
                                        handleChange("minutes", "");
                                    }
                                }}
                                onBlur={() => handleBlur("minutes")}
                                readOnly={isRunning}
                            />
                        </VStack>

                        <span className="dotStyle">:</span>

                        <VStack minW={""}>
                            <Text className="numText">SECONDS</Text>
                            <Input
                                className="inputField"
                                style={{animation: animations.popIn}}
                                type={"number"}
                                fontWeight={"500"}
                                fontFamily="TT Norms Pro"
                                name="seconds"
                                pattern="\d*"
                                inputMode="numeric"
                                value={time.seconds.toString().padStart(2, "0")}
                                min={0}
                                max={59}
                                onChange={(event) => {
                                    if (event?.target.value < 60) {
                                        handleChange("seconds", event.target.value);
                                    } else {
                                        handleChange("seconds", "");
                                    }
                                }}
                                onBlur={() => handleBlur("seconds")}
                                readOnly={isRunning}
                            />
                        </VStack>
                    </HStack>
                </Box>
            </Container>
        );
    };

    useInterval(
        // This function uses the useInterval hook to decrement the timer by 1 second.
        // If the timer reaches 0, it sets isRunning to false and isTimeUp to true.
        // Otherwise, it decrements the seconds by 1, or decrements the minutes by 1 and sets seconds to 59 if seconds is 0.
        () => {
            const {minutes, seconds} = time;
            if (minutes === 0 && seconds === 0) {
                setIsRunning(false);
                setIsTimeUp(true);
            } else if (seconds === 0) {
                setTime((prevTime) => ({
                    ...prevTime,
                    minutes: minutes - 1,
                    seconds: 59,
                }));
            } else {
                setTime((prevTime) => ({...prevTime, seconds: seconds - 1}));
            }
        },
        isRunning ? 1000 : null
    );

    return (
        <ChakraProvider theme={theme}>
            <AnimatePresence/>
            <Container fluid className={"mb-5"}>
                <Row>
                    <Col xs={12} lg={6} md={6} className="text-center text-center">
                        <Image
                            src="/images/ZID_Logo_RGB_def.webp"
                            alt="Logo"
                            className="image mb-5 mb-lg-0 logo-mobile-centered"
                        />
                    </Col>
                    <Col
                        xs={12}
                        lg={6}
                        md={6}
                        className="d-flex align-items-center justify-content-center mt-1"
                    >

                    </Col>
                </Row>
                <Row className="justify-content-center funktionierendlechmau">
                    <Col xs={12} md={6}>
                        <Flex
                            direction="column"
                            align="center"
                            justify="center"
                            className={"whaaat"}

                        >
                            {time.minutes === 0 && time.seconds === 0 && isTimeUp ? (
                                [
                                    <Text
                                        key="timeup"
                                        className="text"
                                        mt={100}
                                        style={{animation: animations.popIn}}
                                    >
                                        TIME IS UP
                                    </Text>,
                                    <Button
                                        key="btn"
                                        backgroundColor={"#4696c1"}
                                        style={{animation: animations.popIn}}
                                        onClick={handleRestart}
                                        size="xl"
                                        borderRadius={"3px"}
                                        padding={"40px"}
                                        mt={50}
                                    >
                                        <img
                                            width={"50px"}
                                            height={"50px"}
                                            src="/images/icons8-wiederkehrender-termin-100.png"
                                            alt="RESET"
                                        />
                                    </Button>,
                                    isTimeUp && <Confetti width={width} height={height}/>,
                                ]
                            ) : (
                                <>
                                    <Box display="flex" mt={0}>
                                        {isRunning ? (
                                            <Container className="fixedHeight">
                                                <Box>
                                                    <HStack
                                                        spacing={8}
                                                        justify="center"
                                                        alignItems={"center"}
                                                    >
                                                        <VStack minW={"40%"}>
                                                            <Text className="numText">MINUTES</Text>
                                                            <Input
                                                                className="inputFieldafterLeft"
                                                                style={{animation: animations.popIn}}
                                                                type={"number"}
                                                                fontWeight={"500"}
                                                                fontFamily="TT Norms Pro"
                                                                name="minutes"
                                                                pattern="\d*"
                                                                inputMode="numeric"
                                                                value={time.minutes.toString().padStart(2, "0")}
                                                                min={0}
                                                                max={99}
                                                                onBlur={() => handleBlur("minutes")}
                                                                readOnly={isRunning}
                                                            />
                                                        </VStack>

                                                        <Text className="dotStyle">:</Text>

                                                        <VStack minW={"40%"}>
                                                            <Text className="numText">SECONDS</Text>
                                                            <Input
                                                                className="inputFieldafter"
                                                                style={{animation: animations.popIn}}
                                                                type={"number"}
                                                                fontWeight={"500"}
                                                                fontFamily="TT Norms Pro"
                                                                name="seconds"
                                                                pattern="\d*"
                                                                inputMode="numeric"
                                                                value={time.seconds.toString().padStart(2, "0")}
                                                                min={0}
                                                                max={59}
                                                                onBlur={() => handleBlur("seconds")}
                                                                readOnly={isRunning}
                                                            />
                                                        </VStack>
                                                    </HStack>
                                                </Box>
                                            </Container>
                                        ) : (
                                            InputFields()
                                            // inputFields
                                        )}
                                    </Box>
                                    {isRunning ? (
                                        <div>
                                            <Button
                                                style={{animation: animations.popIn}}
                                                onClick={handlePause}
                                                class="button"
                                            >
                                                <img
                                                    width={"50px"}
                                                    height={"50px"}
                                                    src="/images/icons8-pause-90.png"
                                                    alt="PAUSE"
                                                />
                                            </Button>
                                            <Button
                                                style={{animation: animations.popIn}}
                                                onClick={handleReset}
                                                class="button"
                                            >
                                                <img
                                                    width={"50px"}
                                                    height={"50px"}
                                                    src="/images/icons8-wiederkehrender-termin-100.png"
                                                    alt="RESET"
                                                />
                                            </Button>
                                        </div>
                                    ) : (
                                        <Button
                                            style={{animation: animations.popIn}}
                                            backgroundColor={"#4696c1"}
                                            size="xl"
                                            onClick={handleStart}
                                            borderRadius={"3px"}
                                            padding={"40px"}
                                            mt={5}
                                            mb={50}
                                        >
                                            <img
                                                width={"50px"}
                                                height={"50px"}
                                                src="/images/icons8-spielen-90.png"
                                                alt="START"
                                            />
                                        </Button>
                                    )}
                                </>
                            )}
                        </Flex>
                    </Col>
                </Row>
            </Container>
        </ChakraProvider>
    );
}

export default Countdown;
